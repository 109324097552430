// ? TabsItems
import { mapActions, mapGetters } from 'vuex';
// ? SERVICE
import service        from '@/services/soporte'
import DialogForm     from '@/components/Dialogs/DialogForm/DialogForm.vue'


var currentDate = new Date().toISOString().substr(0,10);
export default {
    name : "PauseTicket",
    components : {
        DialogForm
    },
    props : {
        dialogFinalize : {
            type    : Boolean,
            default : false
        },
        isEditFinalize : {
            type    : Boolean,
            default : false
        },
        dataTicket : {
            type    : Object,
            default : () => {}
        },
    },
    data : () => ({
        valid                  : true,
        lazy                   : false,
        idTrTicket             : -1,
        estatus                : undefined,
        updated_at             : undefined,
        solucion               : undefined,
        fechaRegistroFinalizar : currentDate,
        errores : {
            solucion : false,
        },
        rules : {
            solucion : [ v => !!v || 'La solución es obligatoria' ],
        }
    }),
    watch : {
        'dataTicket' : function(){
            this.idTrTicket             = this.dataTicket.id_soporteTrTicket;
            this.updated_at             = this.dataTicket.updated_at;
            this.estatus                = this.dataTicket.estatus;
        }
    },
    computed : {
        ...mapGetters( "Ticket", { dataSource : "getDataSource"} )
    },
    methods : {
        ...mapActions("DialogForm",["setDialog"]),
        ...mapActions( "Ticket", ["setDataSource"] ),
        getTitle() {
            return "Finalización";
        },
        eventClose () {
            this.reset();
            // this.$emit("closeDialogFinalize", false)
            this.setDialog({
                key    : "dialogFinalize",
                status : false
            });
        },
        eventGen () {
            this.createFinalizar();
        },
        validate () {
            return this.$refs.form.validate()
        },
        reset () {
            this.$refs.form.reset();
        },
        resetValidation () {
            this.$refs.form.resetValidation()
        },
        createFinalizar(){
            let arrInfo = [];
            if( this.validate() ){
                let objTrTicket = {
                    id_soporteTrTicket  : this.idTrTicket,
                    id_baseCtEstatus    : 4, // finalizar 
                    updated_at          : this.updated_at
                }
                this.$fn.setIdDocMod( 1 ); // Soporte
            
                arrInfo.push( this.$JsTable.getFormat( "soporteTrTicket", "Update", [ objTrTicket ], [ "id_soporteTrTicket" ] ) );
                arrInfo.push( this.$JsTable.getFormat( "baseDtFlujoEstatus", "Insert", [ this.$fn.getTableDtFlujo( this.idTrTicket, 4 ) ], [ "id_baseDtFlujoEstatus" ] ) );
                arrInfo.push( this.$JsTable.getFormat( "baseDsFlujoEstatus", "Insert", [ this.$fn.getTableDsFlujo( this.solucion ) ], [ "id_baseDsFlujoEstatus" ] ) );

                service.SopU_finalizarTicket( arrInfo ).then( result => {
                    if( this.$Msg.fn( { data : result, message : "Ticket finalizado correctamente" } ) ) {
                        this.eventClose();
                        this.readTableTicket();
                    }
                })
            }
        },
        readTableTicket() {
            service.SopR_dataSourceTickets().then( result => {
                this.setDataSource( this.$JsTable.toObj( result.data ) );
            } )
        },

    },
    created() {

    },
    mounted() {
    }
}
